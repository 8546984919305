import { graphql } from 'gatsby';
import React from 'react';
import { StructuredTextDocument } from 'react-datocms/dist/esm';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import Typography from '../../../primitives/typography';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import RowSet from '../../../primitives/grid/row-set';
import Parallax from '../../../animations/components/parallax';
import Container from '../../../primitives/grid/container';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Asset from '../../../primitives/asset';
import AspectRatio from '../../../primitives/aspect-ratio';
import Divider from '../../../primitives/divider';

export type FloatingFeatureCardSetProps = Queries.FloatingFeatureCardSetPropsFragment;

const FloatingFeatureCardSet = ({
    richTitle,
    cards,
}: FloatingFeatureCardSetProps): JSX.Element => (
    <Container css={{
        marginTop: '110px',
        marginBottom: '160px',
        [breakpoints.tb]: {
            marginTop: '80px',
            marginBottom: '90px',
        },
        [breakpoints.mb]: {
            marginTop: '78px',
            marginBottom: '64px',
        },
    }}
    >
        <Typography
            fontSize={{
                dt: 4053,
                tb: 3234,
                mb: 2835,
            }}
            override
            css={{
                textAlign: 'center',
                marginBottom: '84px',
                [breakpoints.mb]: {
                    marginBottom: '58px',
                },
            }}
            fontWeight="light"
        >
            <CustomStructuredTextRenderer data={richTitle as unknown as StructuredTextDocument} />
        </Typography>
        <Row>
            <Col breakpoints={{
                dt: { span: 10, start: 1 },
                tb: { span: 12, start: 0 },
                mb: { span: 4, start: 0 },
            }}
            >
                <Row css={{
                    rowGap: '145px',
                    gridAutoFlow: 'column',
                    [breakpoints.tb]: {
                        rowGap: '100px',
                    },
                    [breakpoints.mb]: {
                        rowGap: '74px',
                    },
                }}
                >
                    {cards && cards.map((card, idx) => {
                        if (!card) return null;

                        const itemsSortedByWidth = card.items.sort((a, b) => (a.width + a.left) - (b.width + b.left));
                        const largestFakeWidth = itemsSortedByWidth[itemsSortedByWidth.length - 1].width;
                        const largestFakeLeft = itemsSortedByWidth[itemsSortedByWidth.length - 1].left;
                        const largestWidth = largestFakeWidth + largestFakeLeft;

                        const allRealHeights = card.items.map((card) => {
                            const singleWidthRatio = card.width / card.image.width;
                            const actualHeightAsPixels = card.image.height * singleWidthRatio;
                            return actualHeightAsPixels + card.top;
                        }).sort((a, b) => a - b);

                        const largestHeight = allRealHeights[allRealHeights.length - 1];

                        const multiplier = largestWidth;
                        const heightMultiplier = largestHeight;

                        const maxWidth = heightMultiplier > multiplier ? '400px' : '505px';
                        const maxWidthMb = heightMultiplier > multiplier ? '240px' : '420px';

                        const col1 = (
                            <Col breakpoints={{
                                dt: {
                                    span: 6,
                                    start: idx % 2 === 0 ? 6 : 0,
                                },
                                mb: {
                                    span: 4,
                                    start: 0,
                                },
                            }}
                            >
                                <RowSet
                                    breakpoints={{
                                        dt: { between: 22 },
                                        mb: { between: 30 },
                                    }}
                                    css={{
                                        maxWidth: '538px',
                                        width: '88%',
                                        marginLeft: idx % 2 === 0 ? '0' : 'auto',
                                        marginRight: idx % 1 === 0 ? '0' : 'auto',
                                        [breakpoints.tb]: {
                                            width: '100%',
                                            margin: 'auto',
                                        },
                                    }}
                                >
                                    <Typography
                                        fontSize={{
                                            dt: 4053,
                                            tb: 3234,
                                            mb: 2027,
                                        }}
                                        override
                                        color="primaryOrange"
                                        css={{
                                            textAlign: 'left',
                                            [breakpoints.mb]: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        fontWeight="medium"
                                    >
                                        {card.title}
                                    </Typography>
                                    <Typography
                                        fontSize={{
                                            dt: 1822,
                                            mb: 1420,
                                        }}
                                        override
                                        css={{
                                            opacity: 0.75,
                                            textAlign: 'left',
                                            p: {
                                                marginBottom: '12px',
                                            },
                                            [breakpoints.mb]: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: card.body,
                                        }}
                                    />
                                </RowSet>
                            </Col>
                        );

                        const col2 = (
                            <Col breakpoints={{
                                dt: {
                                    span: 6,
                                    start: idx % 2 === 0 ? 0 : 6,
                                },
                                mb: {
                                    span: 4,
                                    start: 0,
                                },
                            }}
                            >
                                <AspectRatio
                                    breakpoints={{
                                        dt: { width: largestWidth, height: largestHeight },
                                    }}
                                    css={{
                                        maxWidth,
                                        margin: 'auto',
                                        width: '100%',
                                        [breakpoints.mb]: {
                                            maxWidth: maxWidthMb,
                                        },
                                    }}
                                >
                                    <div css={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '100%',
                                    }}

                                    >
                                        {card.items.sort((a, b) => a.index - b.index).map((item, idx) => {
                                            const singleWidthRatio = item.width / item.image.width;
                                            const actualHeightAsPixels = item.image.height * singleWidthRatio;

                                            const x = (() => {
                                                if (idx < 2) {
                                                    return idx % 2 === 0 ? [3 * (idx + 1), 0] : [-3 * (idx + 1), 0];
                                                }
                                                return [0, 0];
                                            })();

                                            const y = (() => {
                                                if (idx < 2) {
                                                    return idx % 2 === 0 ? [3 * (idx + 1), -3 * (idx + 1)] : [-3 * (idx + 1), 3 * (idx + 1)];
                                                }
                                                return [-20, 6 * idx];
                                            })();

                                            return (
                                                <div style={{
                                                    width: `${(item.width / multiplier) * 100}%`,
                                                    left: `${(item.left / multiplier) * 100}%`,
                                                    height: `${(actualHeightAsPixels / heightMultiplier) * 100}%`,
                                                    top: `${(item.top / heightMultiplier) * 100}%`,
                                                    position: 'absolute',
                                                }}
                                                >
                                                    <Parallax
                                                        css={{
                                                            width: '100%',
                                                            height: '100%',
                                                        }}
                                                        x={x}
                                                        y={y}
                                                    >
                                                        <div css={{
                                                            borderRadius: borderRadius.large,
                                                            boxShadow: '0 0 8px #0001',
                                                            width: '100%',
                                                            height: '100%',
                                                            transform: 'translateZ(0px)',
                                                        }}
                                                        >
                                                            <Asset
                                                                css={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                                asset={item.image}
                                                            />
                                                        </div>
                                                    </Parallax>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </AspectRatio>
                            </Col>
                        );

                        return (
                            <>
                                {col2}
                                {col1}
                            </>
                        );
                    })}
                </Row>
            </Col>
        </Row>
        <Divider css={{
            marginTop: '160px',
            marginBottom: '160px',
            [breakpoints.tb]: {
                marginTop: '90px',
                marginBottom: '90px',
            },
            [breakpoints.mb]: {
                display: 'none',
            },
        }}
        />
    </Container>
);

export const query = graphql`
    fragment FloatingFeatureCardSetProps on DatoCmsFloatingFeatureCardSet {
        richTitle {
            blocks
            links
            value
        }
        cards {
            title
            body
            items {
                index
                image {
                    ...DatoCmsInternalAsset
                }
                top
                left
                width
            }
        }
    }
`;

export default FloatingFeatureCardSet;
